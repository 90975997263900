import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogTitle, DialogContent, 
  TextField, Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const PREFIX = "SetNameModal";
const classes = {
  dialogContent: `${PREFIX}-dialogContent`,
  dialogActions: `${PREFIX}-dialogActions`,
}
const RootDialog = styled(Dialog)(({theme}) => ({
  [`& .${classes.dialogContent}`]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  [`& .${classes.dialogActions}`]: {
    margin: theme.spacing(1),
  },
}));

const SetNameModal = ({ account, handleSetName, handleClose }) => {
  const [name, setName] = useState(account?.name || '');
  const isValid = () => name.length && name.length < 100;

  useEffect(() => {
    setName(account?.name || '')
  }, [account])

  const handleSubmit = e => {
    e.preventDefault();
    handleSetName(name);
    handleClose();
  };

  return (
    <RootDialog open={!!account} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
            Nadaj nazwę
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <TextField
            inputProps={{ maxLength: 100, style: { textAlign: 'center' } }}
            label='Nazwa'
            variant='outlined'
            type='text'
            value={name}
            onChange={({ target }) => setName(target.value)}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button color='primary' type='button' onClick={handleClose}>
            Anuluj
          </Button>
          <Button
            disabled={!isValid()}
            color='primary'
            variant='contained'
            type='submit'
            onClick={handleSubmit}
          >
            Zatwierdź
          </Button>
        </DialogActions>
      </form>
    </RootDialog>
  );
};


export default SetNameModal;
