import React, {useEffect, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography,} from '@mui/material';
import BaseLoader from '../../../utils/BaseLoader';
import {styled} from '@mui/material/styles';
import * as actions from "../../../../actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

const PREFIX = "CreateUpdateModal";
const classes = {
  form: `${PREFIX}-form`,
  formControl: `${PREFIX}-formControl`,
}
const StyledDialog = styled(Dialog)(({theme}) => ({
  [`& .${classes.form}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  [`& .${classes.formControl}`]: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
}));


const mapStateToProps = state => ({
  updateOrder: state.updatePartnerAccounts,
});

const CreateUpdateModal = ({actions, updateOrder}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (updateOrder?.data?.url) {
      window.location.replace(updateOrder.data.url)
    }
  }, [updateOrder]);

  const toggleOpen = () => setIsOpen(isOpen => !isOpen);

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    setIsOpen(false);
    actions.updatePartnerAccounts();
  };

  return (
    <>
      <Button variant='outlined' color='primary' onClick={toggleOpen}>
        Rozpocznij aktualizację
      </Button>
      <BaseLoader loading={loading} onClick={toggleOpen}/>
      <StyledDialog open={isOpen && !loading} onClose={toggleOpen}>
        <DialogTitle>Aktualizuj listę kont bankowych</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>
              Aktualizacja odbywa się za pośrednictwem usługi FiberID
            </Typography>
            <Typography>
              Możesz zaktualizować informację o swoich kontach bankowych udostępniając dane rachunku lub dokonując
              przelewu
              weryfikacyjnego
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleOpen}>Anuluj</Button>
          <Button
            variant='outlined'
            color='primary'
            onClick={handleSubmit}
            disabled={loading}
          >
            Rozpocznij
          </Button>
        </DialogActions>
      </StyledDialog>
    </>
  );
}


const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateUpdateModal);
