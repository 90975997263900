import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../../actions';
import SetNameModal from './SetNameModal';
import AccountsTable from './AccountsTable';
import SetDefaultModal from './SetDefaultModal';
import AccountsUpdateContainer from './update/AccountsUpdateContainer';
import BasePageContent from '../../utils/BasePageContent';
import {Button, Grid} from '@mui/material';
import {Link} from 'gatsby';

const mapStateToProps = state => ({
  accounts: state.getPartnerAccounts,
  setPartnerAccountName: state.setPartnerAccountName,
  partner: state.getPartner,
  // setPartnerAccountAsVat: state.setPartnerAccountAsVat,
});

class Accounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: [],
      loading: true,
      setNameAccount: null,
      setDefaultAccount: null,
    };
  }

  componentDidMount() {
    this.props.actions.getPartnerAccounts();
    this.props.actions.getPartner();
  }

  componentDidUpdate(prevProps) {
    const {
      accounts,
      setPartnerAccountName,
      setPartnerAccountAsVat,
    } = this.props;

    if (accounts !== prevProps.accounts) {
      this.setState({ accounts: accounts.data, loading: false });
    }

    if (
      setPartnerAccountName !== prevProps.setPartnerAccountName ||
      setPartnerAccountAsVat !== prevProps.setPartnerAccountAsVat
    ) {
      this.setState({ loading: true });
      this.props.actions.getPartnerAccounts();
    }
  }

  openSetNameModal = account => {
    this.setState({ setNameAccount: account });
  };

  closeSetNameModal = () => {
    this.setState({ setNameAccount: null });
  };

  openSetDefaultModal = account => {
    this.setState({ setDefaultAccount: account });
  };

  closeSetDefaultModal = () => {
    this.setState({ setDefaultAccount: null });
  };

  handleSetName = name => {
    this.props.actions.setPartnerAccountName(this.state.setNameAccount.code, {
      name,
    });
  };

  isPartnerVerified() {
    const { partner } = this.props;
    return !(!partner ||
      partner.status === 'ERROR' ||
      partner.data.kycStatus !== 'accepted');
  }

  render() {
    const { loading, accounts, setNameAccount, setDefaultAccount } = this.state;
    return (
      <BasePageContent
        title='Twoje konta'
        loading={!this.props.partner || loading}
      >
        {this.isPartnerVerified() ? (
          <>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <AccountsTable
                  accounts={accounts}
                  openSetNameModal={this.openSetNameModal}
                  openSetDefaultModal={this.openSetDefaultModal}
                  setDefaultPartnerAccount={this.openSetDefaultModal}
                />
              </Grid>
              <Grid item xs={12}>
                <AccountsUpdateContainer/>
              </Grid>
            </Grid>
            <SetNameModal
              account={setNameAccount}
              handleSetName={this.handleSetName}
              handleClose={this.closeSetNameModal}
            />
            <SetDefaultModal
              partner={this.props.partner}
              account={setDefaultAccount}
              handleSetDefault={this.props.actions.setDefaultPartnerAccount}
              handleClose={this.closeSetDefaultModal}
            />
          </>
        ) : (
          <Link to='/user/identity'>
            <Button color='primary' variant='outlined'>
              Przejdź do ustawień weryfikacji tożsamości
            </Button>
          </Link>
        )}
      </BasePageContent>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);
