import React, {useEffect} from 'react';
import {bindActionCreators} from "redux";
import * as actions from "../../../../actions";
import {connect} from "react-redux";
import {Alert, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

const mapStateToProps = state => ({
  accountVerificationOrders: state.partnerBankAccountVerificationOrders,
});

const statusMap = {
  new: "W trakcie",
  in_progress: "W trakcie",
  accepted: "Zaakceptowano",
  rejected: "Odrzucono",
}

const AccountVerificationOrdersList = ({actions, accountVerificationOrders}) => {
  useEffect(() => {
    actions.getPartnerAccountVerificationOrders();
  }, []);

  if (!accountVerificationOrders) return <></>;

  if (!accountVerificationOrders?.data.length) return (
    <Alert severity='info'>
      Nie aktualizowano informacji o rachunkach bankowych
    </Alert>
  );

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Kod zlecenia</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Data rozpoczęcia</TableCell>
            <TableCell/>
          </TableRow>
        </TableHead>
        <TableBody>
          {accountVerificationOrders?.data.map(order => (
            <TableRow>
              <TableCell>{order.code}</TableCell>
              <TableCell>{statusMap[order.status]}</TableCell>
              <TableCell>{order.createdAt}</TableCell>
              <TableCell>
                <Button onClick={() => window.location.replace(order.url)}>
                  Przejdź do zlecenia aktualizacyjnego
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountVerificationOrdersList);
