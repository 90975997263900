import React from 'react';
import Layout from '../../templates/layout';
import Accounts from '../../components/user/accounts/Accounts';

const AccountsPage = () => (
  <Layout >
    <Accounts />
  </Layout>
);

export const Head = () => <title>Konta bankowe | Zapłatomat</title>;


export default AccountsPage;
