import React from 'react';
import {Grid, Typography} from '@mui/material';
import AccountVerificationOrdersList from './AccountVerificationOrdersList';
import CreateUpdateModal from './CreateUpdateModal';

const AccountUpdateContainer = () => (
  <Grid container spacing={1}>
    <Grid container item xs={12} justifyContent='space-between'>
      <Grid item>
        <Typography variant='subtitle1'>Historia aktualizacji informacji o rachunkach bankowych</Typography>
      </Grid>
      <Grid item>
        <CreateUpdateModal/>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <AccountVerificationOrdersList/>
    </Grid>
  </Grid>
);

export default AccountUpdateContainer;
