import React from 'react';
import {
  TableContainer,
  TableCell,
  TableHead,
  Table,
  TableRow,
  TableBody,
  Switch,
} from '@mui/material';
import StyledTableCell from '../../utils/StyledTableCell';
import { styled } from '@mui/material/styles';

const formatIban = (iban) => {
  const splitted = iban.match(/.{1,4}/g);
  splitted[0] = splitted[0].match(/.{2}/g).join(' ')
  return splitted.join(' ');
}

const AccountsTable = ({ accounts, openSetDefaultModal }) => (
  <TableContainer>
    <Table aria-label='accounts table'>
      <TableHead>
        <TableRow>
          <StyledTableCell>Numer konta</StyledTableCell>
          <StyledTableCell align='right'>Domyślne</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {accounts?.length &&
          accounts.map(account => (
            <TableRow hover key={account.code}>
              <IbanCell>{formatIban(account.iban)}</IbanCell>
              <TableCell align='right'>
                <Switch
                  color='primary'
                  checked={account.isDefault}
                  onChange={() => {
                    if (account.isDefault) return;
                    openSetDefaultModal(account);
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  </TableContainer>
);

const IbanCell = styled(TableCell)(({theme}) => ({
  [theme.breakpoints.down('md')]: {
    padding: 0,
    paddingLeft: theme.spacing(1),
  },
}));

export default AccountsTable;
