import React, { Component } from 'react';
import { Dialog, DialogActions, DialogTitle, DialogContent, 
  DialogContentText, Button, Typography,
} from '@mui/material';

class SetDefaultModal extends Component {
  componentDidUpdate(prevProps) {
    const { account, partner } = this.props;
    if (account !== prevProps.account || partner !== prevProps.partner) {
      if (account && partner?.data.type === 'individual') {
        this.handleSubmit();
      }
    }
  }

  handleSubmit = () => {
    this.props.handleSetDefault(this.props.account.code);
    this.props.handleClose();
  };

  render() {
    const { account, partner, handleClose } = this.props;
    return (
      <Dialog
        open={!!account && partner?.data.type !== 'individual'}
        onClose={handleClose}
      >
        {account && (
          <>
            <DialogTitle>Ustaw domyślne konto bankowe</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Upewnij się, że wybrane konto nie jest kontem VAT.
              </DialogContentText>
              <Typography>{account.name}</Typography>
              <Typography>Nr konta: {account.iban}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Anuluj</Button>
              <Button
                onClick={this.handleSubmit}
                variant='outlined'
                color='primary'
              >
                Zatwierdź
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  }
}

export default SetDefaultModal;
